.new{
    display: flex;
    width: 100%;

    .newContainer{
        flex:6;

        .top{
            padding: 1.25rem;
            margin: 1.25rem;
            position: relative;
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.4);

            .title{
                margin-top: 2rem;
                color: lightgray;
            }
            .updateButton{
                display: flex;
                align-items: center;
                position: absolute;
                top: 1.25rem;
                left: 1.25rem;

                .icon{
                    color: #f7775e;
                }

                .link{
                    color: #f7775e;
                    text-decoration: none;
                }
            }
        }

        .bottom{
            form{
                width: 80%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 3rem auto;

                .width100{
                    flex-basis: 100%;
                    display: flex;
                    flex-direction: column;

                    label{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 2rem;
    
                        input{
                            padding: 0.5rem;
                            border: none;
                            border-bottom: 1px solid lightgray;
                            outline: none;
                        }

                        select{
                            margin-top: 0.5rem;
                        }
                    }
                }

                fieldset{
                    &:first-child{
                        flex-basis: 60%;
                    }
                    &:nth-of-type(2){
                        flex-basis: 30%;
                    }
                    border: none;
                    legend{
                        margin-bottom:2rem;
                        text-transform: uppercase;
                        font-weight: bold;
                        color: rgb(181, 181, 181);
                    }
                    label{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 2rem;
    
                        input{
                            padding: 0.5rem;
                            border: none;
                            border-bottom: 1px solid lightgray;
                            outline: none;
                        }

                        select{
                            margin-top: 0.5rem;
                        }
                    }

                    &.checkbox{
                        label{
                            flex-direction: row;
                            align-items: center;

                            input{
                                margin: 0 1rem 0 2rem;
                            }
                        }
                    }
                }

                .textarea{
                    flex-basis: 100%;
                    display: flex;
                    flex-direction: column;
                    & textarea{
                        min-height: 8rem;
                        padding: 0.5rem;
                        margin: 1rem 0 2rem;
                    }
                }

                button{
                    flex-basis: 100%;
                    padding: 1rem;
                    border-radius: 0.25rem;
                    border: 1px solid #f7775e;
                    color: white;
                    background-color: #f7775e;
                    cursor: pointer;

                    &:hover{
                        border: 1px solid #f7775e;
                        color: #f7775e;
                        background-color: transparent;
                    }
                }
            }
        }
    }
}
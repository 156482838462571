.datagrid{
  height: 20rem !important;
  margin-top: 2rem;

  .cellAction{
    display: flex;
    align-items: center;
    gap: 1rem;

    .viewButton{
      cursor: pointer;

      .link{
          color: #F36265;
          text-decoration: none;
      }
    }
    .updateButton{
        cursor: pointer;

        .link{
            color: #ffa024;
            text-decoration: none;
        }
    }
    .deleteButton{
        color: rgb(210, 0, 0);
        cursor: pointer;
    }
  }
}
.sidebar{
    flex: 1;
    min-height: 100vh;
    border-right: 0.5px solid rgb(230,230,230);
    background: linear-gradient(#F36265, #961276);
    position: relative;

    .top{
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo{
            font-size: 2rem;
            color: white;
        }
    }

    .center{
        padding-left: 1rem;
        padding-right: 1rem;

        ul{
            list-style: none;
            padding-left: 0;

            .title{
                text-transform: uppercase;
                font-size: 1rem;
                color: white;
                font-weight: bold;
                margin-top: 1.5rem;
                margin-bottom: 0.5rem;
                background-color: #ffb4b6;
                padding: 1rem 0.5rem;
            }

            li {
                display: flex;
                align-items: center;
                padding: 0.5rem;
                cursor: pointer;

                &:hover{
                    // background-color: #f4ede5;
                    background-color: #ffb4b6;
                }

                .icon{
                    font-size: 1.5rem;
                    color: white;
                }

                a{
                    text-decoration: none;
                }

                span{
                    font-size: 0.8rem;
                    color: white;
                    margin-left: 0.5rem;
                }
            }
        }
    }

    .bottom{
        font-size: 0.8rem;
        color: #ffb4b6;
        text-align: center;
        margin-top: 5rem;
    }
}
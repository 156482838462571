.admin{
    .top{
       height: 5rem; 
       display: flex;
       align-items: center;
       justify-content: space-between;
       padding: 2rem;
       background: linear-gradient(#F36265, #961276);
       color: white;

       .logo{
           font-family: "Destination";
           font-size: 4rem;
       }

       .link{
           color: white;
           text-decoration: none;
       }
    }
}
.single{
    display: flex;

    .singleContainer{
        flex: 6;

        .top{
            padding: 1rem;
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.4);

            section{
              display: flex;
              justify-content: space-between;
              align-items: center;

              .link{
                color: #F36265;
                text-decoration: none;
              }
            }
        }

        .center{
          margin-top: 2rem;

          .item{
            img{
              max-width: 20%;
            }

            .details{

              .itemTitle{
                width: 100%;
              }
              .content{
                display: flex;
                flex-wrap: wrap;
                .left{
                  width: 50%;
                }
                .right{
                  width: 50%;
                }
              }
            }
          }
        }
    }
}
.navbar{
    height: 5rem;
    border-bottom: 0.5px solid rgb(230, 230, 230);
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    color: #555;

    .wrapper{
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1.25rem;
        justify-content: space-between;

        .search{
            display: flex;
            align-items: center;
            border: 0.5px solid lightgray;
            padding: 0.25rem;

            input{
                border: none;
                outline: none;
                background-color: transparent;

                &::placeholder{
                    font-size: 0.75rem;
                }
            }
        }

        .items{
            display: flex;

            .item{
                margin: 0.5rem;

                .icon{
                    font-size: 1.25rem;
                }

                .avatar{
                    width: 1.8rem;
                    height: 1.8rem;
                    border-radius: 50%;
                }
            }
        }
    }
}
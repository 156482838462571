.dashboard{
    display: flex;

    .dashboardContainer{
        flex: 6;
        text-align: left;

        .contain{
            padding: 1.25rem;
            .top{
            
            }
    
            .center{
                display: flex;
                flex-wrap: wrap;
                .left{
                    flex: 1;
                    padding: 1.25rem;

                    .title{
                      font-weight: bold;
                    }
                }
                .right{
                    flex: 1;
                    padding: 1.5rem;

                    .title{
                      font-weight: bold;
                    }
                }
            }
        }
    }
}
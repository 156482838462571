.update{
    display: flex;

    .updateContainer{
        flex: 6;

        .top{
            padding: 1rem;
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.4);

            .updateButton{
                display: flex;
                align-items: center;

                .icon{
                    color: #F36265;
                }

                .link{
                    color: #F36265;
                    text-decoration: none;
                }
            }
        }

        form{
          width: 50%;
          margin: 2rem auto;
          display: flex;
          flex-direction: column;
          align-items: center;

          label{
            width: 100%;
            display: flex;
            flex-direction: column;
            text-align: left;
            margin-top: 2rem;

            input, select{
              margin-top: 1rem;
              padding: 0.5rem 0;
              border: 1px solid rgba(201, 201, 201, 0.8);
              color: rgb(145, 145, 145);
            }
          }

          button{
            margin: 2rem;
            color: #F36265;
            border: 1px solid #F36265;
            background-color: white;
            padding: 1rem 1.5rem;
          }
        }
    }
}
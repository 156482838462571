.login{
    form{
        width: 30%;
        margin: 4rem auto;
        padding: 4rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.4);
        
        label{
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 2rem;

            span{
                margin-bottom: 0.5rem;
            }

            input{
                padding: 0.5rem;
                border: none;
                border-bottom: 1px solid lightgray;
                outline: none;
            }
        }

        button{
            flex-basis: 100%;
            align-self: center;
            padding: 1rem;
            border-radius: 0.25rem;
            border: 1px solid #F36265;
            color: white;
            background-color: #F36265;
            cursor: pointer;

            &:hover{
                border: 1px solid #F36265;
                color: #F36265;
                background-color: transparent;
            }
        }
    }
}
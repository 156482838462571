.table{
  .rows{
    display: flex;
    flex-direction: column;

    .infos{
      display: flex;
      padding: 0.5rem 0;

      span{
        flex: 3;
      }
      .groups{
          flex: 1;
          display: flex;
          justify-content: space-between;

          span{
              text-align: center;
          }
      }
    } 
  }
}